<template>
  <div class="container_address">
    <clone-tab :bgcShow="bgcShow"></clone-tab>

    <!-- 地址为空 -->
    <div class="address_icon" v-if="addressList.length==0">
      <img src="../../assets/Slice38.png" />
      <div>暂无数据</div>
    </div>

    <!-- 数据列表  -->
    <address-list v-else @init="init" :deleteShow="deleteShow" :addressList="addressList"></address-list>

    <div class="addBtn" @click="addAddress">新增地址</div>

  </div>
</template>
<script>
import cloneTab from "../../components/expenditure/cloneTab";
import addressList from "../../components/addressList";
import { listAddress_api } from "@/api/deal";
export default {
  data() {
    return {
      bgcShow: true,
      dataList: true,
      addressList: [],
      deleteShow: false,
      address: null
    };
  },
  components: {
    cloneTab,
    addressList
  },
  created() {
    document.title = "地址管理";
    this.getList();
    if (JSON.parse(localStorage.getItem("address"))) {
      this.address = JSON.parse(localStorage.getItem("address"));
      console.log(this.address);
    }
  },
  methods: {
    addAddress() {
      this.$router.push("../address/detail");
    },
    // 数据列表
    getList() {
      listAddress_api().then(res => {
        console.log(res);
        if (res.code == 0) {
          this.addressList = res.data;
          this.addressList.forEach(e => {
            e.selectShow = false;

            if (this.address) {
              if (e.id == this.address.id) {
                e.selectShow = true;
              }
            } else {
              if (e.is_default == 1) {
                e.selectShow = true;
              }
            }
          });
        }
      });
    },
    init() {
      this.getList();
    },
    delectBtn() {
      this.deleteShow = true;
    }
  }
};
</script>
<style>
.container_address {
  padding-top: 30px;
  height: 100%;
}
.address_icon {
  width: 400px;
  height: 400px;
  border-radius: 0px 0px 0px 0px;
  opacity: 1;
  margin: 300px auto 0;
  font-size: 30px;
  color: #ccc;
  text-align: center;
}
.address_icon img {
  width: 100%;
  height: 100%;
}
.addBtn {
  width: 400px;
  height: 90px;
  background: #2d2929;
  border-radius: 50px 50px 50px 50px;
  opacity: 1;
  border: 2px solid #e7c48b;
  position: fixed;
  bottom: 196px;
  left: 50%;
  transform: translateX(-50%);
  text-align: center;
  line-height: 88px;
  font-size: 30px;
  color: #e7c58c;
}
.delete {
  font-size: 30px;
  display: flex;
  text-align: right;
  justify-content: flex-end;
  margin-right: 20px;
}
.delete img {
  width: 30px;
  height: 30px;
  margin-left: 10px;
}
</style>